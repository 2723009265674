import React from "react"
import Table from "react-bootstrap/Table"
import { Link } from "gatsby"
import WebsiteBannerAurovilleEvent from "../images/newDesign/banners/WebsiteBannerAurovilleEvent.jpg"
import Carousel from "react-multi-carousel"
import BannerData from "../assets/data/banners.json"
import auroville from "../images/newDesign/auroville/AurovilleEventWebsitephotos.jpg"
import eventLocation from "../images/newDesign/auroville/even.jpg"
import Brochure from "../images/newDesign/auroville/pdfs/Auroville-ESGRetreatBrochure.pdf"
export default function AuroVilleProgram() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <div className="container-custom">
        <Carousel
          className="carousal-custom"
          responsive={responsive}
          itemClass="carousel-item-padding-40-px"
          draggable={false}
          showDots={false}
          infinite={true}
          autoPlay={false}
          arrows={false}
          autoPlaySpeed={3000}
        >
          {BannerData.map(data => {
            if (data.attributes.banner_type === "auroville_event") {
              return (
                <div>
                  <div className="banner-btn-parent">
                    <Link className="nav-link" to={data.attributes.button_url}>
                      {data.attributes.button_lable && (
                        <button className="btn banner-btn -green mt-4">
                          {data.attributes.button_lable}
                        </button>
                      )}
                    </Link>
                  </div>
                  <div>
                    <img src={data.attributes.banner.data[0].attributes.url} />
                  </div>
                </div>
              )
            }
          })}
        </Carousel>

        <div className="mt-5 pt-5 mb-5 px-3 px-md-5 px-lg-5 px-xl-5">
          <a
            href={Brochure}
            target="_blank"
            className="btn learn-more-btn  my-4"
          >
            Click to view the Brochure
          </a>
          <br />
          <br />
          <div className="comman-para">
            <div className="row align-items-center">
              {/* <div className="col-12 col-sm-12 col-md-4 col-lg-2">
              <img src={mother} className="img-fluid" />
            </div> */}
              <div className="col-12 col-sm-12 col-md-8 col-lg-10">
                Auroville wants to be a universal town where men and women of
                all countries are able to live in peace and progressive harmony,
                above all creeds, all politics and all nationalities. The
                purpose of Auroville is to realize human unity.
                <h6 className="text-end fw-bold">
                  - Mirra Alfassa (Founder of Auroville)
                </h6>
              </div>
            </div>
          </div>
          <h1 className="heading my-5">ABOUT THE EVENT</h1>
          <div className="comman-para">
            The ESG Retreat is a one-of-a-kind event designed for individuals
            passionate about sustainability, taking place in Auroville, a place
            dedicated to the vision of "A City the Earth Needs," often referred
            to as the "City of Dawn." Over the past five decades, Auroville has
            been at the forefront of implementing diverse sustainability
            initiatives, including renewable energy and storage, water
            management, waste management, forest area conservation and
            preservation, and organic farming, among others.
          </div>
          <div className="row align-items-center">
            <div className="col-md-6">
              <h1 className="heading my-5">
                A FEW INTERESTING FACTS ABOUT AUROVILLE:
              </h1>
              <div className="comman-para">
                <ul>
                  <li>
                    It is a location near Pondicherry which was inaugurated on
                    February 28th, 1968, with the participation of 124 nations.
                  </li>
                  <li>
                    Auroville was recognised and taken up by the Government of
                    India, under the Auroville Foundation Act 1988, which was
                    passed by the Indian Parliament.. The Governing Board
                    selected by the Government of India consists of seven
                    prominent Indians in the fields of education, culture,
                    environment and social service in the areas of Auroville's
                    ideals.
                  </li>
                  <li>
                    UNESCO had endorsed the project of Auroville through four
                    resolutions, in 1966, 1968, 1970, and 1983 and their
                    association dates back in 1966,
                    <br />
                    Auroville has 3000+ inhabitants, drawn from some 60+
                    countries.
                  </li>

                  <li>
                    It has within it a landscape 3000 Farms over 372 acres, 80+
                    waste water treatment plants, 43+ afforestation projects and
                    1244 acres of forests.
                  </li>
                  <li>
                    The ESG Retreat is a one-of-a-kind event designed for
                    individuals passionate about sustainability, taking place in
                    Auroville, a place dedicated to the vision of "A City the
                    Earth Needs," often referred to as the "City of Dawn." Over
                    the past five decades, Auroville has been at the forefront
                    of implementing diverse sustainability initiatives,
                    including renewable energy and storage, water management,
                    waste management, forest area conservation and preservation,
                    and organic farming, among others.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <img src={auroville} className="img-fluif" />
            </div>
          </div>

          <div className="comman-para">
            Over the years, Auroville has won international acclaim for its
            efforts in sustainable development practices that are unfolding in
            the context of the individual and collective transformation of
            consciousness.
            <br />
            <br />
            {/* <div className="row"> */}
            <div className="col-with-hover">
              <a
                href="https://auroville.org/page/the-unity-pavilion"
                target="_blank"
              >
                <div className="image-container-auroville">
                  <img src={eventLocation} className="img-fluid" />
                </div>
                {/* <div className="link-overlay link-overlay-auroville"> */}
                {/* <b>Event Location</b> - UNITY PAVILLION */}
                {/* </div> */}
              </a>
            </div>
            {/* <div className="col-md-6"></div> */}
            {/* </div> */}
          </div>

          <h1 className="heading my-5">KEY SESSIONS</h1>

          <div className="comman-para">
            The ESG Retreat will share the true “Spirit of Sustainability”
            through various sessions on aspects of E,S & G , bringing forth the
            experiences of various professionals in each aspect. It is and
            enriched experience of all at once material, economic and
            psychological with a spiritual foundation.
            <br />
            <br />
            We have organized two-day sessions designed to comprehensively
            address the various facets of ESG, encompassing the practical
            aspects of execution, solutions, and challenges, in addition to
            imparting subject matter expertise on ESG components. These sessions
            will provide a concise outline of the program, incorporating both
            on-site visits and indoor sessions to share insights gleaned from
            over five decades of Auroville's experience.
          </div>

          <Table
            responsive
            striped
            bordered
            style={{ fontSize: "19px", textAlign: "center" }}
            className="mb-4"
          >
            <thead>
              <tr>
                <th className="heading background-price-td">Session Name</th>
                <th className="heading background-price-td">Session Details</th>
                <th className="heading background-price-td">
                  About the Organisation
                </th>
                <th className="heading background-price-td">Project Aspects</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={4}>SESSIONS - DAY 1</td>
              </tr>
              <tr>
                <td>Aurovill Spirit of Sustainability - An Introduction</td>
                <td>
                  Aurovill its Vision, Key Objectives, Principles and Governance
                  Mechanism{" "}
                </td>
                <td>
                  <a href="https://auroville.org" target="_blank">
                    https://auroville.org
                  </a>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Sacred Groves</td>
                <td>
                  Ecologically Balanced and Sustainable Model of Construction
                  (Site Visit)
                </td>
                <td>
                  <a href="http://sacredgroves.in/" target="_blank">
                    http://sacredgroves.in/
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=0r-3Cc4CqqM"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=0r-3Cc4CqqM
                  </a>
                </td>
              </tr>
              <tr>
                <td>Effects of Trees in Urban Setting</td>
                <td>
                  Impact of Trees on our lives, surroundings in an urban setting
                  (Session)
                </td>
                <td>
                  <a href="http://www.treescapes.in/" target="_blank">
                    http://www.treescapes.in/
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=qpwG0lXapUc"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=qpwG0lXapUc
                  </a>
                </td>
              </tr>
              <tr>
                <td>Auroville Botanical Gardens</td>
                <td>
                  Conservation and Preservation Methodologies (Site Visit)
                </td>
                <td>
                  <a
                    href="https://auroville-botanical-gardens.org/"
                    target="_blank"
                  >
                    https://auroville-botanical-gardens.org/
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=tPuCw24brOU"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=tPuCw24brOU
                  </a>
                </td>
              </tr>
              <tr>
                <td>ESG Regulations and Reporting</td>
                <td>
                  ESG Regulations Landscape - International and National
                  perspective (Session with examples on various frameworks)
                </td>
                <td>
                  <a href="https://www.aurovilleconsulting.com" target="_blank">
                    https://www.aurovilleconsulting.com
                  </a>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={4}>SESSIONS - DAY 2</td>
              </tr>
              <tr>
                <td>Smart Mini Grid</td>
                <td>
                  Integrate Renewable Energy and Storage - to build scalable,
                  financially viable mini grids. (Session)
                </td>
                <td></td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=UM-yr0TvMAE"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=UM-yr0TvMAE
                  </a>
                </td>
              </tr>
              <tr>
                <td>Water Conservation and Waste Water Treatment</td>
                <td>
                  Technical Approach to decentralised wastewater treatment which
                  can provide long term cost effective solutions. (Site Visit)
                </td>
                <td>
                  <a
                    href="https://auroville.org/page/auroville-centre-for-scientific-research-csr"
                    target="_blank"
                  >
                    https://auroville.org/page/auroville-centre-for-scientific-research-csr
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=wiEnUaksW7w"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=wiEnUaksW7w
                  </a>
                </td>
              </tr>
              <tr>
                <td>Eco Service Management</td>
                <td>
                  Solid Waste Management system - Collecting and Processing for
                  large communities (Site Visit)
                </td>
                <td>
                  <a
                    href="https://auroville.org/page/waste-management"
                    target="_blank"
                  >
                    https://auroville.org/page/waste-management
                  </a>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Anitya Community</td>
                <td>
                  The Approach, Values and Ethos for Community Building and
                  Development
                </td>
                <td>
                  <a href="https://joyofimpermanence.in/" target="_blank">
                    https://joyofimpermanence.in/
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=GWeJt1-F2V4"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=GWeJt1-F2V4
                  </a>
                </td>
              </tr>
              <tr>
                <td>Decarbonisation and Offsetting - a game of ethics</td>
                <td>
                  Approach to achieving net zero. (Session with examples on
                  decarbonisation)
                </td>
                <td>
                  <a href="https://www.aurovilleconsulting.com" target="_blank">
                    https://www.aurovilleconsulting.com
                  </a>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Power of Sound Waves</td>
                <td>
                  Ending the retreat with a variety of sound experiences, from
                  variety of instruments for wellness and inner harmony
                  (Session)
                </td>
                <td>
                  <a href="https://svaram.org/" target="_blank">
                    https://svaram.org/
                  </a>
                </td>
                <td>
                  <a
                    href="https://www.youtube.com/watch?v=PPy2FH-G70M"
                    target="_blank"
                  >
                    https://www.youtube.com/watch?v=PPy2FH-G70M
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>

          <h1 className="heading my-5">PLACE OF STAY</h1>
          <ul className="comman-para">
            <li>
              The Participants can book for their stay in Pondicherry and reach
              event location every morning with cab in just 20-25min.
            </li>
            <li>
              The Participants can also stay in guest houses at Auroville which
              will be closer to the location. The link for guest houses is
              enclosed.
            </li>
          </ul>
          <Table
            responsive
            striped
            bordered
            style={{ fontSize: "19px", textAlign: "center" }}
            className="mb-4"
          >
            <thead>
              <tr>
                <th className="heading background-price-td">Name</th>
                <th className="heading background-price-td">Contact Person</th>
                <th className="heading background-price-td">Contact Number</th>
                <th className="heading background-price-td">Email Id</th>
                <th className="heading background-price-td">
                  Distance between the <br />
                  guest house and the Venue
                </th>
                <th className="heading background-price-td">Website Link</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Auromode</td>
                <td>Anitha</td>
                <td>
                  78715 62343
                  <br />
                  0413 2622224
                </td>
                <td>avapart@gmail.com</td>
                <td>1.4 km</td>
                <td>
                  <a href="https://www.auromode.in" target="_blank">
                    https://www.auromode.in
                  </a>
                  /
                </td>
              </tr>
              <tr>
                <td>Center guest house</td>
                <td>Dodo & christine</td>
                <td>0413 - 2622155</td>
                <td>centerguesthouse@auroville.org.in</td>
                <td>1.0 km</td>
                <td>
                  <a
                    href="https://auroville.org/page/centre-guest-house"
                    target="_blank"
                  >
                    https://auroville.org/page/centre-guest-house
                  </a>
                </td>
              </tr>
              <tr>
                <td>Verite</td>
                <td>Kathir</td>
                <td>0413 2622045, 606</td>
                <td>verite.gh@gmail.com</td>
                <td>2.2 km</td>
                <td>
                  <a href="https://www.verite.in/guest.php" target="_blank">
                    https://www.verite.in/guest.php
                  </a>
                </td>
              </tr>
              <tr>
                <td>Atithi graha</td>
                <td>Dominique, Lakshmi</td>
                <td>0413 2622445</td>
                <td>atithigriha@auroville.org.in</td>
                <td>close by</td>
                <td>
                  <a
                    href="https://auroville.org/page/atithi-griha-guesthouse"
                    target="_blank"
                  >
                    https://auroville.org/page/atithi-griha-guesthouse
                  </a>
                </td>
              </tr>
              <tr>
                <td>Arka</td>
                <td>Ramana</td>
                <td>093855 11668</td>
                <td>arka@auroville.org.in</td>
                <td>1.4 km</td>
                <td>
                  <a
                    href="https://www.google.com/travel/hotels/s/TMvvq5SxbADUEUEx5"
                    target="_blank"
                  >
                    https://www.google.com/travel/hotels/s/TMvvq5SxbADUEUEx5
                  </a>
                </td>
              </tr>
              <tr>
                <td>Afsaneh</td>
                <td>Rosy Shankar</td>
                <td>
                  (0413) 2622048 <br /> (0) 94881 73360
                </td>
                <td>afsanah_gh@auroville.org.in</td>
                <td>2.2 km</td>
                <td>
                  <a href="https://auroville.org/page/afsanah-guest-house">
                    https://auroville.org/page/afsanah-guest-house
                  </a>
                </td>
              </tr>
              <tr>
                <td>Swagatham</td>
                <td>santhosh</td>
                <td>0413 2622934</td>
                <td>swagathamguesthouse@auroville.org.in</td>
                <td>close by</td>
                <td>
                  <a href="https://guesthouses.auroville.org/swagatham-guest-house/">
                    https://guesthouses.auroville.org/swagatham-guest-house/
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}
